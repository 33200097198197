// src/stores/user-store.ts

import { defineStore } from 'pinia'

// 定义用户状态的 Pinia Store
export const useUserStore = defineStore('user', {
  state: () => ({
    userId: '', // 用户ID
    userName: '', // 用户名
    mobile: '', // 手机号
    email: '', // 用户邮箱
    nickname: '', // 昵称
    avatar: '', // 头像
    token: '', // 用户令牌
    _isAuthenticated: false, // 私有的用户认证状态
    memberSince: '', // 用户加入日期
    pfp: 'https://picsum.photos/id/22/200/300', // 用户头像
    is2FAEnabled: false, // 是否启用了双因素认证
  }),

  getters: {
    isAuthenticated: (state) => state._isAuthenticated,
  },

  actions: {
    // 切换双因素认证状态
    toggle2FA() {
      this.is2FAEnabled = !this.is2FAEnabled
    },

    // 更新用户名
    changeUserName(userName: string) {
      this.userName = userName
    },

    // 设置用户信息和登录状态
    setUser(userData: {
      userId: string
      username: string
      token: string
      email: string
      memberSince: string
      mobile?: string
      nickname: string
      avatar: string
    }) {
      this.userId = userData.userId
      this.userName = userData.username
      this.mobile = userData.mobile || ''
      this.email = userData.email
      this.nickname = userData.nickname
      this.avatar = userData.avatar
      this.token = userData.token
      this.memberSince = userData.memberSince
      this._isAuthenticated = true
    },

    // 清除用户信息和登录状态
    clearUser() {
      this.userId = ''
      this.userName = ''
      this.mobile = ''
      this.email = ''
      this.nickname = ''
      this.avatar = ''
      this.token = ''
      this.memberSince = ''
      this._isAuthenticated = false
    },

    // 从 localStorage 恢复用户状态
    loadUserFromLocalStorage() {
      const savedUser = localStorage.getItem('user')
      if (savedUser) {
        const userData = JSON.parse(savedUser)
        this.setUser(userData)
      } else {
        this._isAuthenticated = false
      }
    },

    // 检查用户是否已认证（可用于异步验证）
    async checkAuthentication() {
      // 这里可以添加额外的逻辑，比如验证 token 是否有效
      // 现在我们只是返回当前的认证状态
      return this._isAuthenticated
    },

    // 新增: 模拟用户登录状态（仅用于本地测试）
    mockUserLogin(mockUserData: {
      userId: string
      username: string
      email: string
      nickname: string
      avatar: string
      mobile?: string // 添加可选的 mobile 属性
    }) {
      const token = 'mock-token-' + Math.random().toString(36).substr(2, 9)
      const memberSince = new Date().toISOString()

      this.setUser({
        ...mockUserData,
        token,
        memberSince,
        mobile: mockUserData.mobile || '', // 如果没有提供 mobile，则使用空字符串
      })

      console.log('模拟用户登录成功:', this.userName)
      console.log('认证状态:', this._isAuthenticated)
    },
  },
})
