<template>
  <svg
    id="图层_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 198 44"
    style="enable-background: new 0 0 198 44"
    xml:space="preserve"
  >
    <g>
      <path
        d="M0.7,41.38V32c1.7,1.43,3.54,2.5,5.54,3.21s4,1.07,6.04,1.07c1.19,0,2.23-0.11,3.12-0.32
		  c0.89-0.21,1.63-0.51,2.23-0.89c0.6-0.38,1.04-0.83,1.33-1.35s0.44-1.08,0.44-1.68c0-0.82-0.23-1.55-0.7-2.2s-1.11-1.24-1.92-1.79
		  c-0.81-0.55-1.77-1.07-2.89-1.58c-1.11-0.51-2.31-1.03-3.6-1.55C7,23.55,4.56,21.88,2.94,19.91c-1.61-1.97-2.42-4.35-2.42-7.15
		  c0-2.19,0.44-4.07,1.32-5.64c0.88-1.57,2.08-2.87,3.59-3.88c1.51-1.02,3.27-1.76,5.26-2.24c1.99-0.48,4.1-0.72,6.33-0.72
		  c2.19,0,4.13,0.13,5.82,0.4c1.69,0.26,3.25,0.67,4.67,1.22v8.76c-0.7-0.49-1.47-0.92-2.3-1.29c-0.83-0.37-1.68-0.68-2.56-0.92
		  c-0.88-0.24-1.75-0.42-2.62-0.54c-0.87-0.12-1.69-0.18-2.48-0.18c-1.07,0-2.05,0.1-2.93,0.31S13,8.52,12.39,8.89
		  c-0.61,0.37-1.07,0.82-1.41,1.33c-0.33,0.52-0.5,1.1-0.5,1.74c0,0.7,0.19,1.33,0.56,1.89c0.37,0.56,0.9,1.08,1.58,1.58
		  c0.68,0.5,1.51,0.99,2.49,1.46c0.98,0.48,2.08,0.97,3.31,1.48c1.68,0.7,3.19,1.45,4.53,2.24c1.34,0.79,2.49,1.68,3.44,2.68
		  c0.96,1,1.69,2.13,2.2,3.41c0.51,1.28,0.76,2.77,0.76,4.47c0,2.34-0.44,4.31-1.33,5.9c-0.89,1.59-2.09,2.88-3.62,3.87
		  s-3.3,1.7-5.32,2.12c-2.02,0.43-4.16,0.64-6.4,0.64c-2.3,0-4.5-0.2-6.58-0.59S2.23,42.16,0.7,41.38z"
        fill="#0076fe"
      />
      <path
        d="M62.31,30.63H42.74c0.31,4.36,3.06,6.53,8.23,6.53c3.3,0,6.2-0.78,8.7-2.34v6.68
		  c-2.77,1.48-6.38,2.23-10.81,2.23c-4.84,0-8.6-1.34-11.28-4.03c-2.68-2.69-4.01-6.43-4.01-11.24c0-4.98,1.45-8.93,4.34-11.84
		  c2.89-2.91,6.45-4.37,10.66-4.37c4.38,0,7.76,1.3,10.15,3.9c2.39,2.6,3.59,6.12,3.59,10.58V30.63z M53.73,24.94
		  c0-4.3-1.74-6.45-5.21-6.45c-1.48,0-2.77,0.62-3.85,1.85s-1.74,2.76-1.98,4.6H53.73z"
        fill="#0076fe"
      />
      <path
        d="M94.78,30.63H75.21c0.31,4.36,3.06,6.53,8.23,6.53c3.3,0,6.2-0.78,8.7-2.34v6.68
		  c-2.77,1.48-6.38,2.23-10.81,2.23c-4.84,0-8.6-1.34-11.28-4.03s-4.01-6.43-4.01-11.24c0-4.98,1.45-8.93,4.34-11.84
		  c2.89-2.91,6.45-4.37,10.66-4.37c4.38,0,7.76,1.3,10.15,3.9c2.39,2.6,3.59,6.12,3.59,10.58V30.63z M86.19,24.94
		  c0-4.3-1.74-6.45-5.21-6.45c-1.48,0-2.77,0.62-3.85,1.85s-1.74,2.76-1.98,4.6H86.19z"
        fill="#0076fe"
      />
      <path
        d="M119.85,21.34c-1.11-0.6-2.41-0.91-3.9-0.91c-2.01,0-3.58,0.74-4.72,2.21c-1.13,1.48-1.7,3.48-1.7,6.02v14.33
		  h-9.26v-30h9.26v5.57h0.12c1.46-4.06,4.1-6.09,7.91-6.09c0.98,0,1.74,0.12,2.29,0.35V21.34z"
        fill="#0076fe"
      />
      <path
        d="M159.55,40.18c-4.1,2.36-9.2,3.54-15.29,3.54c-6.76,0-12.08-1.87-15.98-5.61s-5.84-8.89-5.84-15.45
		  c0-6.6,2.13-11.98,6.39-16.14s9.92-6.24,16.99-6.24c4.45,0,8.38,0.62,11.78,1.85V11c-3.24-1.88-7.21-2.81-11.89-2.81
		  c-3.93,0-7.12,1.27-9.59,3.82s-3.71,5.95-3.71,10.21c0,4.32,1.11,7.66,3.33,10.02c2.22,2.36,5.21,3.54,8.98,3.54
		  c2.27,0,4.06-0.32,5.39-0.97v-8.2h-8.41v-7.56h17.87V40.18z"
        fill="#0076fe"
      />
      <path
        d="M181.11,43.72c-5,0-8.93-1.4-11.79-4.2s-4.29-6.61-4.29-11.41c0-4.96,1.48-8.84,4.45-11.65
		  c2.97-2.8,6.98-4.2,12.04-4.2c4.98,0,8.89,1.4,11.72,4.2c2.83,2.8,4.25,6.51,4.25,11.12c0,4.98-1.46,8.92-4.38,11.81
		  C190.19,42.28,186.19,43.72,181.11,43.72z M181.35,19.35c-2.19,0-3.89,0.75-5.1,2.26c-1.21,1.5-1.82,3.63-1.82,6.39
		  c0,5.76,2.32,8.64,6.97,8.64c4.43,0,6.65-2.96,6.65-8.88C188.06,22.15,185.82,19.35,181.35,19.35z"
        fill="#0076fe"
      />
    </g>
  </svg>
</template>
