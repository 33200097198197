export interface INavigationSysRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationSysRoute[]
}
export default {
  routes: [
    {
      name: 'settings',
      displayName: 'menu.settings',
      meta: {
        icon: 'settings',
      },
    },
    {
      name: 'helps',
      displayName: 'menu.helps',
      meta: {
        icon: 'quiz',
      },
    },
    {
      name: 'logout',
      displayName: 'menu.logout',
      meta: {
        icon: 'logout',
      },
    },
  ] as INavigationSysRoute[],
}
