// 从 Vue 3 包中导入 createApp 方法，用于创建应用实例。
import { createApp } from 'vue'

// 导入应用的国际化配置，i18n 用于管理多语言支持。
import i18n from './i18n'

// 从 Vuestic UI 包中导入 createVuestic 方法，初始化 UI 组件库。
import { createVuestic } from 'vuestic-ui'

// 从 @gtm-support/vue-gtm 包中导入 createGtm 方法，用于集成 Google Tag Manager。
import { createGtm } from '@gtm-support/vue-gtm'

// 导入全局状态管理配置，通常是 Vuex 或 Pinia 实现的 store。
import stores from './stores'

// 导入应用的路由配置，管理不同路径的组件展示。
import router from './router'

// 导入 Vuestic UI 的全局配置，定制全局组件样式和行为。
import vuesticGlobalConfig from './services/vuestic-ui/global-config'

// 导入应用的根组件，整个应用的主组件。
import App from './App.vue'

// 导入用户状态管理的 Pinia Store，用于管理用户登录状态。
import { useUserStore } from './stores/user-store'

// 使用 createApp 方法创建 Vue 应用实例，并传入根组件 App。
const app = createApp(App)

// 注册全局状态管理到应用实例，使得状态管理在整个应用中可用。
app.use(stores)

// 注册路由管理到应用实例，使得路由功能在应用中启用。
app.use(router)

// 注册国际化插件到应用实例，使得多语言支持在整个应用中可用。
app.use(i18n)

// 注册 Vuestic UI 插件，并传入全局配置，初始化 UI 组件库。
app.use(createVuestic({ config: vuesticGlobalConfig }))

// 恢复用户状态
const userStore = useUserStore() // 获取 user-store 实例
userStore.loadUserFromLocalStorage() // 从 localStorage 中恢复用户状态

// 检查环境变量 VITE_APP_GTM_ENABLED 是否为 true，决定是否启用 GTM。
if (import.meta.env.VITE_APP_GTM_ENABLED) {
  // 如果 GTM 启用，则注册 GTM 插件，并传入 GTM ID 和路由配置。
  app.use(
    createGtm({
      id: import.meta.env.VITE_APP_GTM_KEY, // GTM 的唯一标识
      debug: false, // 禁用 debug 模式
      vueRouter: router, // 传入 Vue Router 实例以跟踪路由变化
    }),
  )
}

// 将应用实例挂载到 HTML 中 id 为 "app" 的元素上，使得应用的 UI 在页面上显示。
app.mount('#app')
