<template>
  <VaNavbar class="app-layout-navbar py-2 px-0" style="border-bottom: #e6e6e7 solid 1px">
    <template #left>
      <div class="left" style="padding-left: 17px">
        <Transition v-if="isMobile" name="icon-fade" mode="out-in">
          <VaIcon
            color="primary"
            :name="isSidebarMinimized ? 'menu' : 'close'"
            size="24px"
            style="margin-top: 3px"
            @click="isSidebarMinimized = !isSidebarMinimized"
          />
        </Transition>
        <RouterLink to="/" aria-label="Visit home page">
          <VaIcon :component="JtIconLogo" style="width: 198px; height: 44px; color: #0076ff; margin: 20px 0" />
        </RouterLink>
      </div>
      <div class="top-search" style="display: none">
        <VaInput class="top-input" placeholder="搜索数据、用户、文档">
          <template #prependInner>
            <VaIcon :component="JtIconSearch" />
          </template>
        </VaInput>
      </div>
    </template>
    <template #right>
      <AppNavbarActions class="app-navbar__actions" :is-mobile="isMobile" />
    </template>
  </VaNavbar>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '../../stores/global-store'
import AppNavbarActions from './components/AppNavbarActions.vue'
import JtIconLogo from '../icons/JtIconLogo.vue'
import JtIconSearch from '../icons/JtIconSearch.vue'

defineProps({
  isMobile: { type: Boolean, default: false },
})

const GlobalStore = useGlobalStore()

const { isSidebarMinimized } = storeToRefs(GlobalStore)
</script>

<style lang="scss" scoped>
.va-navbar {
  z-index: 2;
  --va-navbar-padding-x: 24px;

  @media screen and (max-width: 950px) {
    .left {
      width: 100%;
    }

    .app-navbar__actions {
      display: flex;
      justify-content: space-between;
    }
  }
}

.left {
  display: flex;
  align-items: center;
  margin-left: 1rem;

  & > * {
    margin-right: 1rem;
  }

  & > *:last-child {
    margin-right: 0;
  }

  .logo-icon {
    width: 198px;
    height: 44px;
    color: #0076ff;
  }
}

.icon-fade-enter-active,
.icon-fade-leave-active {
  transition: transform 0.5s ease;
}

.icon-fade-enter,
.icon-fade-leave-to {
  transform: scale(0.5);
}

.top-search {
  height: 88px;
  width: 488px;
  display: flex;
  align-items: center;
  margin-left: 138px;

  :deep(.va-input) {
    border-radius: 12px !important;
  }

  :deep(.va-input-wrapper__field),
  :deep(.va-input-wrapper__fieldset) {
    border-radius: 12px !important;
  }
}

:deep(.va-layout-fixed-wrapper) {
  height: 104px;
}

:deep(.va-navbar__left .st0) {
  fill: #0076ff;
  clip-path: none;
}

:deep(.top-search) {
  .va-input-wrapper__size-keeper,
  .va-input-wrapper__container,
  .va-input-wrapper__field {
    height: 56px;
  }

  .va-input-wrapper__field {
    padding: 8px 20px;
    --va-input-wrapper-items-gap: 16px;
    border-radius: 12px;
  }
}
</style>
