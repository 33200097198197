<template>
  <svg
    id="图层_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 30 30"
    style="enable-background: new 0 0 30 30"
    xml:space="preserve"
  >
    <g>
      <g>
        <defs>
          <rect id="SVGID_1_" x="2" y="1.5" width="26" height="27" />
        </defs>
        <clipPath id="SVGID_00000070093689452677531630000001715200342100802727_">
          <use xlink:href="#SVGID_1_" style="overflow: visible" />
        </clipPath>
        <path
          style="clip-path: url(#SVGID_00000070093689452677531630000001715200342100802727_); fill: #5a6577"
          d="M27.73,26.93
			l-4.19-4.24c4.74-4.77,4.82-12.4,0.17-17.26c-4.65-4.86-12.36-5.21-17.44-0.8c-5.08,4.42-5.72,12.02-1.44,17.2
			c4.28,5.18,11.94,6.09,17.33,2.05l4.25,4.31c0.35,0.36,0.93,0.37,1.29,0.02c0.17-0.17,0.27-0.4,0.28-0.64
			C27.99,27.33,27.9,27.1,27.73,26.93L27.73,26.93z M3.85,13.93c0-4.3,2.62-8.18,6.64-9.83c4.02-1.65,8.65-0.74,11.72,2.31
			c3.08,3.04,4,7.62,2.33,11.59c-1.67,3.97-5.59,6.57-9.94,6.56C8.67,24.56,3.86,19.8,3.85,13.93L3.85,13.93z M3.85,13.93"
        />
      </g>
    </g>
  </svg>
</template>
<style type="text/css">
.st0 {
  clip-path: url(#SVGID_00000093895463006414844790000005803521936841775793_);
  fill: #5a6577;
}
</style>
