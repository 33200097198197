<template>
  <div class="max-w-[1600px] mx-auto px-[44px] flex justify-between items-center py-8">
    <div>Copyright &copy; 2024 深圳市鲸腾品牌管理有限公司 粤ICP备05041688号 粤公网安备 44030502168168号</div>
    <div>使用条款 · 法律声明 · 隐私政策</div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
/* 底部样式 */
</style>
