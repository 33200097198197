<template>
  <VaSidebar v-model="writableVisible" :width="sidebarWidth" class="jt-sidebar" color="#fafbfd" minimized-width="0">
    <div class="sidebar-profile">
      <div class="avatar-wrapper">
        <img src="/src/assets/images/avatar.jpg" alt="Profile Avatar" />
      </div>
      <div class="nickname">
        <span>Jinmozhe</span>
      </div>
    </div>

    <VaAccordion v-model="value" multiple>
      <VaCollapse v-for="(route, index) in navigationRoutes.routes" :key="index">
        <template #header="{ value: isCollapsed }">
          <VaSidebarItem
            :to="route.children ? undefined : { name: route.name }"
            :active="routeHasActiveChild(route)"
            :active-color="activeColor"
            :text-color="textColor(route)"
            :aria-label="`${route.children ? 'Open category ' : 'Visit'} ${t(route.displayName)}`"
            role="button"
            hover-opacity="0.10"
          >
            <VaSidebarItemContent style="padding: 0 0 0 48px; justify-content: center">
              <VaIcon
                v-if="route.meta.icon"
                aria-hidden="true"
                :name="route.meta.icon"
                size="30px"
                :color="iconColor(route)"
                style="margin-right: 24px"
              />
              <VaSidebarItemTitle class="flex justify-between items-center leading-5 font-semibold">
                {{ t(route.displayName) }}
                <VaIcon v-if="route.children" :name="arrowDirection(isCollapsed)" size="30px" />
              </VaSidebarItemTitle>
            </VaSidebarItemContent>
          </VaSidebarItem>
        </template>
        <template #body>
          <div v-for="(childRoute, index2) in route.children" :key="index2">
            <VaSidebarItem
              :to="{ name: childRoute.name }"
              :active="isActiveChildRoute(childRoute)"
              :active-color="activeColor"
              :text-color="textColor(childRoute)"
              :aria-label="`Visit ${t(route.displayName)}`"
              hover-opacity="0.10"
            >
              <VaSidebarItemContent class="py-3 pr-2 pl-11">
                <VaSidebarItemTitle class="leading-5 font-semibold">
                  {{ t(childRoute.displayName) }}
                </VaSidebarItemTitle>
              </VaSidebarItemContent>
            </VaSidebarItem>
          </div>
        </template>
      </VaCollapse>
    </VaAccordion>

    <div
      role="separator"
      class="va-divider va-divider--center my-6"
      aria-orientation="horizontal"
      aria-hidden="true"
      style="--va-color-computed: #e6e6e7"
    >
      <!---->
    </div>

    <VaAccordion v-model="value" multiple>
      <VaCollapse v-for="(route, index) in navigationSysRoutes.routes" :key="index">
        <template #header>
          <VaSidebarItem
            :to="{ name: route.name }"
            :active="routeHasActiveChild(route)"
            :active-color="activeColor"
            :text-color="textColor(route)"
            :aria-label="`Visit ${t(route.displayName)}`"
            role="button"
            hover-opacity="0.10"
          >
            <VaSidebarItemContent style="padding: 0 0 0 48px; justify-content: center">
              <VaIcon
                v-if="route.meta.icon"
                aria-hidden="true"
                :name="route.meta.icon"
                size="30px"
                :color="iconColor(route)"
                style="margin-right: 24px"
              />
              <VaSidebarItemTitle class="flex justify-between items-center leading-5 font-semibold">
                {{ t(route.displayName) }}
              </VaSidebarItemTitle>
            </VaSidebarItemContent>
          </VaSidebarItem>
        </template>
        <!-- #body 模板被移除 -->
      </VaCollapse>
    </VaAccordion>

    <div class="updated">
      <img src="/src/assets/images/updatead.jpg" alt="Profile Avatar" />
    </div>
  </VaSidebar>
</template>
<script lang="ts">
import { defineComponent, watch, ref, computed } from 'vue'
import { useRoute } from 'vue-router'

import { useI18n } from 'vue-i18n'
import { useColors } from 'vuestic-ui'

import navigationRoutes, { type INavigationRoute } from './NavigationRoutes'
import navigationSysRoutes from './NavigationSysRoutes'

export default defineComponent({
  name: 'Sidebar',
  props: {
    visible: { type: Boolean, default: true },
    mobile: { type: Boolean, default: false },
  },
  emits: ['update:visible'],

  setup: (props, { emit }) => {
    const { getColor, colorToRgba } = useColors()
    const route = useRoute()
    const { t } = useI18n()

    const value = ref<boolean[]>([])

    const writableVisible = computed({
      get: () => props.visible,
      set: (v: boolean) => emit('update:visible', v),
    })

    const isActiveChildRoute = (child: INavigationRoute) => route.name === child.name

    const routeHasActiveChild = (section: INavigationRoute) => {
      if (!section.children) {
        return route.path.endsWith(`${section.name}`)
      }

      return section.children.some(({ name }) => route.path.endsWith(`${name}`))
    }

    const setActiveExpand = () =>
      (value.value = navigationRoutes.routes.map((route: INavigationRoute) => routeHasActiveChild(route)))

    const sidebarWidth = computed(() => (props.mobile ? '100vw' : '320px'))
    const color = computed(() => getColor('background-secondary'))
    const activeColor = computed(() => colorToRgba(getColor('primary'), 0.3))

    const iconColor = (route: INavigationRoute) => (routeHasActiveChild(route) ? '#184e91' : '#5a6577')
    const textColor = (route: INavigationRoute) => (routeHasActiveChild(route) ? 'primary' : 'textPrimary')
    const arrowDirection = (state: boolean) => (state ? 'va-arrow-up' : 'va-arrow-down')

    watch(() => route.fullPath, setActiveExpand, { immediate: true })

    return {
      writableVisible,
      sidebarWidth,
      value,
      color,
      activeColor,
      navigationRoutes,
      navigationSysRoutes,
      routeHasActiveChild,
      isActiveChildRoute,
      t,
      iconColor,
      textColor,
      arrowDirection,
    }
  },
})
</script>
<style lang="scss" scoped>
.sidebar-profile {
  display: flex;
  align-items: center;
  padding: 32px 0 40px 34px;
  /* 基于您的图片，这里是上下的间距 */
  background-color: #fafbfd;
  /* 这里是背景色 */
}

.avatar-wrapper {
  /* 如果头像是正方形的图片，这样设置可以使它显示为圆形 */
  margin-right: 8px;
  /* 头像和昵称之间的间距 */
}

.avatar-wrapper img {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  /* 头像边框圆角 */
  overflow: hidden;
  /* 保持图片的原始宽高比 */
}

.nickname span {
  font-size: 26px;
  /* 昵称字体大小 */
  font-weight: bold;
  /* 字体粗细 */
  color: #071326;
  /* 字体颜色 */
}

.va-accordion .va-sidebar-item--active .va-sidebar-item-content {
  color: black;
  background-color: white;
}

.updated {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.updated img {
  border-radius: 12px;
}

.jt-sidebar {
  :deep(.va-sidebar__menu) {
    .va-collapse {
      @apply py-2;
    }

    .va-sidebar-item-content i {
      font-size: 36px !important;
    }

    .va-sidebar-item--active .va-sidebar-item-content i {
      font-size: 38px !important;
    }

    .va-sidebar-item--active .va-sidebar__title {
      @apply text-black;
    }

    .va-sidebar__title {
      @apply text-[16px] text-[#5a6577];
    }
  }
}
</style>
