<template>
  <svg
    id="图层_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 30 30"
    style="enable-background: new 0 0 30 30"
    xml:space="preserve"
  >
    <path
      class="st0"
      d="M23.05,3H6.95C4.22,3,2,5.22,2,7.95v14.1C2,24.78,4.22,27,6.95,27h16.1c2.73,0,4.95-2.22,4.95-4.95V7.95
	C27.99,5.22,25.78,3,23.05,3L23.05,3z M6.95,4.79h16.1c1.54,0.01,2.85,1.12,3.1,2.64l-9.78,5.01c-0.83,0.42-1.81,0.42-2.64,0
	L3.85,7.36C4.14,5.87,5.44,4.8,6.95,4.79L6.95,4.79z M23.05,25.21H6.95c-1.74,0-3.15-1.41-3.16-3.15V9.34l9.12,4.7
	c1.34,0.69,2.93,0.69,4.28,0l9.01-4.61v12.62c0,0.84-0.33,1.64-0.92,2.23C24.69,24.87,23.88,25.2,23.05,25.21L23.05,25.21z
	 M23.05,25.21"
    />
  </svg>
</template>
<style type="text/css">
.st0 {
  fill: #5a6577;
}
</style>
