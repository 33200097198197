<template>
  <div class="sidebar-container">
    <!-- 顶部菜单 -->
    <div class="sidebar-top-menu">
      <RouterLink to="/" aria-label="Visit home page">
        <VaIcon :component="JtIconLogo" style="width: 198px; height: 44px; margin: 10px auto 30px auto" />
      </RouterLink>
      <VaSidebarItem href="/chat" hover-color="#29364b" hover-opacity="1" class="fixed-height bg-black rounded-full">
        <VaSidebarItemContent class="text-white">
          <span class="material-symbols-outlined"> add </span>
          <VaSidebarItemTitle>新建聊天</VaSidebarItemTitle>
        </VaSidebarItemContent>
      </VaSidebarItem>
      <VaSidebarItem href="/" hover-color="#eef2f5" hover-opacity="1" class="fixed-height rounded-full mt-6">
        <VaSidebarItemContent>
          <span class="material-symbols-outlined"> home </span>
          <VaSidebarItemTitle>首页</VaSidebarItemTitle>
        </VaSidebarItemContent>
      </VaSidebarItem>
      <VaSidebarItem
        :to="{ name: 'app', path: '/app' }"
        hover-color="#eef2f5"
        hover-opacity="1"
        class="fixed-height rounded-full"
      >
        <VaSidebarItemContent>
          <span class="material-symbols-outlined"> explore </span>
          <VaSidebarItemTitle>热门流行</VaSidebarItemTitle>
        </VaSidebarItemContent>
      </VaSidebarItem>

      <VaSidebarItem href="#" hover-color="#eef2f5" hover-opacity="1" class="fixed-height rounded-full">
        <VaSidebarItemContent>
          <span class="material-symbols-outlined"> history </span>
          <VaSidebarItemTitle>历史记录</VaSidebarItemTitle>
          <VaBadge color="#eef2f5" text="36" text-color="#5a6577" style="--va-badge-font-size: 0.75rem" />
        </VaSidebarItemContent>
      </VaSidebarItem>
      <VaDivider class="mt-6 mb-3" />
    </div>

    <!-- 聊天记录 -->
    <div class="sidebar-chat-log">
      <VaAccordion v-model="expandedStates" multiple>
        <VaCollapse header="今天">
          <template #content>
            <div class="chat-item">
              <span class="material-symbols-outlined flex-none w-7 text-lg"> chat_bubble </span>
              <div class="chat-content">分析高转化 ASIN 的共同特点，我们可以如何利用数据分析智能生成…</div>
              <span class="material-symbols-outlined flex-none w-4 text-base"> more_vert </span>
            </div>
            <div class="chat-item">
              <span class="material-symbols-outlined flex-none w-7 text-lg"> chat_bubble </span>
              <div class="chat-content">我的目标受众是谁?他们在亚马逊上表现如何?</div>
              <span class="material-symbols-outlined flex-none w-4 text-base"> more_vert </span>
            </div>
            <div class="chat-item">
              <span class="material-symbols-outlined flex-none w-7 text-lg"> chat_bubble </span>
              <div class="chat-content">未来的市场趋势预测是什么:我们应该如何调整关键词…</div>
              <span class="material-symbols-outlined flex-none w-4 text-base"> more_vert </span>
            </div>
          </template>
        </VaCollapse>
        <VaCollapse header="前7天">
          <template #content>
            <div class="chat-item">
              <span class="material-symbols-outlined flex-none w-7 text-lg"> chat_bubble </span>
              <div class="chat-content">我的目标受众是谁?他们在亚马逊上表现如何?</div>
              <span class="material-symbols-outlined flex-none w-4 text-base"> more_vert </span>
            </div>
            <div class="chat-item">
              <span class="material-symbols-outlined flex-none w-7 text-lg"> chat_bubble </span>
              <div class="chat-content">未来的市场趋势预测是什么:我们应该如何调整关键词…</div>
              <span class="material-symbols-outlined flex-none w-4 text-base"> more_vert </span>
            </div>
          </template>
        </VaCollapse>
      </VaAccordion>
    </div>

    <!-- 底部固定 -->
    <div class="fixed-left-bottom">
      <VaDivider class="mb-3" />
      <VaSidebarItem href="#" hover-color="#eef2f5" text-color="#2a364c" hover-opacity="1" class="rounded-full">
        <VaSidebarItemContent class="h-8">
          <span class="material-symbols-outlined"> settings_heart </span>
          <VaSidebarItemTitle>设置</VaSidebarItemTitle>
        </VaSidebarItemContent>
      </VaSidebarItem>
      <VaSidebarItem href="#" hover-color="#eef2f5" text-color="#2a364c" hover-opacity="1" class="rounded-full">
        <VaSidebarItemContent class="h-8">
          <span class="material-symbols-outlined"> help </span>
          <VaSidebarItemTitle>帮助与常见问答</VaSidebarItemTitle>
        </VaSidebarItemContent>
      </VaSidebarItem>
      <VaSidebarItem href="#" hover-color="#eef2f5" text-color="#2a364c" hover-opacity="1" class="rounded-full">
        <VaSidebarItemContent class="h-8">
          <span class="material-symbols-outlined"> support_agent </span>
          <VaSidebarItemTitle>客服中心</VaSidebarItemTitle>
        </VaSidebarItemContent>
      </VaSidebarItem>
      <VaDivider class="my-3" />

      <div class="flex justify-between items-center gap-2">
        <div class="flex-none w-12">
          <VaAvatar color="#000000" font-size="24px" class="mr-6"> N </VaAvatar>
        </div>
        <div class="flex-1">
          <div class="flex items-center">
            <span class="font-bold text-lg">NOUNS</span>
            <VaBadge
              text="PRO"
              color="#0076ff"
              class="ml-2"
              style="
                --va-badge-font-size: 0.7rem;
                --va-badge-text-wrapper-border-radius: 70px;
                --va-badge-text-wrapper-font-weight: 400;
              "
            />
          </div>
          <p class="text-sm text-gray-500">nounsray@gmail.com</p>
        </div>
        <div class="flex-none w-6">
          <span class="material-symbols-outlined"> unfold_more </span>
        </div>
      </div>
      <VaDivider class="mt-1" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { VaAccordion, VaCollapse, VaAvatar } from 'vuestic-ui'
import JtIconLogo from '../icons/JtIconLogo.vue'
const expandedStates = ref([true, false])
</script>

<style lang="scss" scoped>
// Header不要下边框
:deep(.va-collapse__body-wrapper--bordered) {
  border: 0;
}

// header文本颜色
:deep(.va-collapse__header__text) {
  @apply text-gray-400 text-sm;
}

.sidebar-container {
  @apply pt-4 pr-4 pl-4 text-base flex-grow flex flex-col;

  .fixed-height {
    height: 48px;
    /* 固定高度,确保高度一致 */
    display: flex;
    align-items: center;
  }

  .sidebar-top-menu {
    @apply flex-shrink-0;
  }

  .sidebar-chat-log {
    @apply flex-grow mt-4;
  }

  .fixed-left-bottom {
    @apply sticky bottom-0 bg-[#fafbfd] flex-shrink-0;
  }
}

.chat-item {
  @apply flex p-2 border border-gray-200 rounded-xl text-sm text-[#5a6577] mb-2;
}

.chat-content {
  @apply flex-1 leading-6 overflow-hidden text-ellipsis max-h-[50px] line-clamp-2;
}
</style>
