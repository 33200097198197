import { defineStore } from 'pinia'

// 定义一个名为 'global' 的 Pinia Store
export const useGlobalStore = defineStore('global', {
  state: () => {
    return {
      isSidebarMinimized: false, // 表示侧边栏是否被最小化的状态，默认值为 false（未最小化）
    }
  },

  actions: {
    // 定义一个切换侧边栏状态的方法
    toggleSidebar() {
      this.isSidebarMinimized = !this.isSidebarMinimized
      // 切换 isSidebarMinimized 的状态
      // 如果当前为 false，则切换为 true；如果当前为 true，则切换为 false
    },
  },
})

// global-store.ts 用于集中管理全局 UI 状态，如侧边栏的显示或隐藏。通过使用 Pinia，你可以在应用的任何地方访问和修改这些状态，使得状态管理更加简单和一致。
